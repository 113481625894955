import { Modal } from '@grupo-sbf/motriz-nike';
import { useRouter } from 'next/router';
import { StoresModalProps } from '@/src/common/components/StoresModal/StoresModal.types';
import { getCoreRoutes } from '@/src/common/config/routes';
import { trackingEvent } from '@/src/common/utils/tracking';
import {
  HorizontalDivider,
  ParagraphTextModal,
  ObservationTextModal,
  ContainerButton,
  ButtonModal,
} from './StoresModal.styled';

const StoresModal = ({ isOpen, onClose }: StoresModalProps) => {
  const coreRoutes = getCoreRoutes();
  const router = useRouter();
  const handleClickButton = () => {
    if (onClose) {
      onClose();
    }
    trackingEvent({
      event: 'select_content',
      content_type: 'button',
      item_id: 'consult_stores_button',
    });
    router.push(coreRoutes.storeList);
  };
  return (
    <Modal.Blank isOpen={isOpen} onClose={onClose} title="Trocar na loja">
      <HorizontalDivider />
      <ParagraphTextModal>
        Para realizar a troca em loja, não é necessário abrir uma solicitação.
        Apenas leve a <strong>caixa original</strong>, o{' '}
        <strong>próprio produto</strong>, a <strong>etiqueta</strong> e a{' '}
        <strong>nota fiscal</strong>.
      </ParagraphTextModal>
      <ParagraphTextModal>
        Clique no botão abaixo para consultar a loja Nike mais perto de você:
      </ParagraphTextModal>
      <ContainerButton>
        <ButtonModal onClick={handleClickButton}>Consultar lojas</ButtonModal>
      </ContainerButton>
      <ObservationTextModal>
        Obs.: Os produtos estão sujeitos à disponibilidade de estoque na loja
        escolhida.
      </ObservationTextModal>
    </Modal.Blank>
  );
};

export default StoresModal;
