import { Divider, Typography } from '@grupo-sbf/motriz-nike';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';
import Button from '@/src/common/components/Button/Button';
export const HorizontalDivider = styled(Divider).attrs({
  orientation: 'horizontal',
})`
  ${({ theme }) => css`
    border-color: ${theme.color.neutral[300]};
  `}
`;

export const ContainerButton = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing[300]} ${theme.spacing[0]} ${theme.spacing[500]};
  `}
`;

export const ParagraphTextModal = styled(Typography).attrs({
  type: 'paragraph',
  weight: 'normal',
})`
  ${({ theme }) => css`
    padding: ${theme.spacing[100]} ${theme.spacing[0]};
    text-align: left;
    &:first-of-type {
      margin-top: ${theme.spacing[200]};
    }
  `}
`;

export const ObservationTextModal = styled(Typography).attrs({
  type: 'caption',
  weight: 'normal',
})``;

export const ButtonModal = styled(Button)`
  ${media.lessThan('large')} {
    width: 100%;
  }
`;
