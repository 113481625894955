import { Typography } from '@grupo-sbf/motriz-nike';
import Link from 'next/link';
import { useState } from 'react';
import Button from '@/src/common/components/Button/Button';
import ReturnModal from '@/src/common/components/ReturnModal/ReturnModal';
import StoresModal from '@/src/common/components/StoresModal/StoresModal';
import { getCoreRoutes } from '@/src/common/config/routes';
import { trackingEvent } from '@/src/common/utils/tracking';
import {
  ContainerType,
  ReturnBox,
  ContainerTypeBtn,
  ReturnLink,
} from './ProductReturn.styled';

const handleReturnClick = () => {
  trackingEvent({
    event: 'select_content',
    content_type: 'button',
    item_id: 'return_button',
  });
};

const ProductReturn = () => {
  const coreRoutes = getCoreRoutes();
  const [isReturnModalOpened, setIsReturnModalOpened] =
    useState<boolean>(false);
  const [isStoresModalOpened, setIsStoresModalOpened] =
    useState<boolean>(false);

  const openReturnModal = () => {
    trackingEvent({
      event: 'select_content',
      content_type: 'link',
      item_id: 'return_help',
    });
    setIsReturnModalOpened(true);
  };

  const closeReturnModal = () => {
    setIsReturnModalOpened(false);
  };

  const openStoresModal = () => {
    trackingEvent({
      event: 'select_content',
      content_type: 'button',
      item_id: 'return_store_button',
    });
    setIsStoresModalOpened(true);
  };

  const closeStoresModal = () => {
    setIsStoresModalOpened(false);
  };

  return (
    <ReturnBox>
      <ContainerType>
        <Typography type="subtitle">Troca ou Devolução</Typography>

        <Typography type="paragraph">
          Devolva seu produto via Correios ou troque em lojas Nike.
        </Typography>

        <Typography type="paragraph">
          Ao devolver seu produto via Correios, você pode escolher entre receber
          um vale-troca ou solicitar o reembolso. Optando por trocar na loja,
          você pode trocar o seu produto por outro em uma loja Nike mais perto
          de você.
        </Typography>

        <ReturnLink onClick={openReturnModal} hasUnderline>
          {' '}
          Entenda como funciona a devolução por correios
        </ReturnLink>
      </ContainerType>
      <ReturnModal isOpen={isReturnModalOpened} onClose={closeReturnModal} />
      <StoresModal isOpen={isStoresModalOpened} onClose={closeStoresModal} />
      <ContainerTypeBtn>
        <Link href={coreRoutes.returnRoute} passHref legacyBehavior>
          <Button variant="primary" as="a" onClick={handleReturnClick}>
            Devolver via Correios
          </Button>
        </Link>
        <Button variant="secondary" onClick={openStoresModal}>
          Trocar na loja
        </Button>
      </ContainerTypeBtn>
    </ReturnBox>
  );
};

export default ProductReturn;
